.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4rem;
}

.header-text {
  color: var(--color-purple);
  margin-left: 2rem;
  text-transform: uppercase;
}

.info-container {
  background: var(--color-secondary);
  padding: 3rem;
}

.looking-forward {
  color: var(--color-white);
}
.contacts-container {
  margin-top: 3rem;
}

.email {
  color: var(--color-tertiary);
}

.github-svg,
.linkedin-svg {
  width: 3rem;
  padding-right: 2rem;
}

.file-svg {
  width: 2rem;
  padding-right: 1rem;
}

.download-resume {
  color: var(--color-primary);
}

.resume-container {
  margin-top: 2rem;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 1000px) {
  .looking-forward {
    font-size: 42px;
    line-height: 50px;
  }

  .email {
    font-size: 18px;
  }
  .download-resume {
    font-size: 18px;
  }
}

@media screen and (min-width: 1120px) {
  .looking-forward {
    font-size: 48px;
    line-height: 50px;
  }

  .email,
  .download-resume {
    font-size: 24px;
    line-height: 28px;
  }

  .contacts-container {
    margin-top: 6rem;
  }
  .resume-container {
    margin-top: 4rem;
  }
}
