@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;900&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Source Sans Pro", sans-serif;
}

html {
  --color-secondary: #1881f2;
  --color-primary: #98c4f9;
  --color-purple: #bf2be2;
  --color-gray: #444444;
  --color-menuBar: #187ae2;
  --color-tertiary: #f79f2b;
  --color-white: #ffffff;
  scroll-behavior: smooth;
}

h1 {
  font-size: 32px;
  line-height: 35px;
  letter-spacing: 0.5px;
}

h2 {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 25px;
}

h3 {
  font-size: 18px;
  line-height: 38px;
  letter-spacing: 3px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
