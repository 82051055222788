.about {
  flex-direction: column;
  background: var(--color-white);
  padding-bottom: 1rem;
  overflow: hidden;
}

.body-container {
  background: var(--color-tertiary);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: calc(250px + 11rem);
}

.rectangle-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: calc(-250px - 1rem);
}

.desc-blue {
  display: none;
}

.funFact {
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: 2rem;
  width: 21rem;
  color: var(--color-white);
}

.desc-container {
  display: flex;
  align-items: center;
  background: var(--color-white);
  margin-top: -2rem;
  margin-left: -2rem;
  margin-right: 1rem;
  margin-bottom: 3rem;
  height: 250px;
  border-radius: 7px;
}

.desc {
  margin-left: 4rem;
  margin-right: 1rem;
  font-size: 11px;
  line-height: 15px;
  color: var(--color-gray);
}

.header-image {
  margin-left: -0.5rem;
  height: 6rem;
  z-index: -10;
  margin-top: 4rem;
}

.aboutMe {
  color: var(--color-purple);
  margin-left: 2rem;
  margin-top: -4.4rem;
  text-transform: uppercase;
}

@media screen and (min-width: 490px) {
  .desc {
    font-size: 16px;
    line-height: 28px;
  }
}

@media screen and (min-width: 760px) {
  .funFact {
    margin-top: 5rem;
    margin-bottom: 5rem;
    margin-left: 3rem;
  }

  .desc-container {
    flex: 7;
    padding: 0;
    margin: 0;
    height: 300px;
  }

  .desc {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .desc-blue {
    display: flex;
    height: 300px;
    flex: 3;
    border-radius: 7px;
    background-color: var(--color-secondary);
  }

  .body-container {
    width: 70%;
    height: calc(300px + 15rem);
  }

  .rectangle-container {
    margin: 0;
    margin-top: calc(-300px - 3rem);
    padding: 0;
    width: 100%;
  }

  .funFact {
    margin-left: 4rem;
    margin-top: 4rem;
  }
}

@media screen and (min-width: 1000px) {
  .aboutMe {
    font-size: 22px;
    margin-left: 3rem;
    margin-top: -5.5rem;
    text-transform: uppercase;
  }
  .header-image {
    margin-left: -0.5rem;
    height: 8rem;
    z-index: -10;
    margin-top: 4rem;
  }

  .funFact {
    line-height: 45px;
    font-size: 42px;
    margin-top: 4rem;
    margin-left: 3rem;
    width: 25rem;
  }

  .desc {
    font-size: 18px;
    margin-left: 3rem;
    margin-right: 3rem;
  }
}

@media screen and (min-width: 1120px) {
  .desc {
    font-size: 24px;
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .funFact {
    line-height: 52px;
    font-size: 48px;
  }

  .body-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
    height: 35rem;
  }

  .rectangle-container {
    margin-top: -20rem;
  }
}
