.subject-container {
  width: 100%;
  overflow: hidden;
}

.img-container-1 {
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
}

.img-container-2 {
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.project-card-1 {
  background: var(--color-secondary);
  padding: 0.5rem;
  border-radius: 7px;
}

.img-container-1 .project-img-1 {
  height: 12rem;
  padding: 0.05rem;
}

.img-container-2 .project-img-1 {
  height: 7rem;
  padding: 0.05rem;
}

.text-container {
  background: var(--color-primary);
  padding-top: 4rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.project-desc {
  color: var(--color-gray);
  text-align: center;
  padding: 1rem;
}

.project-desc-container {
  border-radius: 7px;
  background-color: var(--color-white);
}

.project-name {
  text-align: center;
  width: 21rem;
  color: var(--color-white);
}

.siteLink {
  z-index: 10;
  color: var(--color-secondary);
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  margin-top: -1rem;
  padding-bottom: 1rem;
}

.github-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -1rem;
  margin-right: -10rem;
  padding-bottom: 5rem;
  overflow: hidden;
}

.github-icon {
  height: 3rem;
  padding-right: 2rem;
  fill: var(--color-tertiary);
}

.github-bar {
  height: 3rem;
}

@media screen and (min-width: 490px) {
  .img-container-1 .project-img-1 {
    height: 15rem;
    padding: 0.05rem;
  }

  .img-container-2 .project-img-1 {
    height: 10rem;
    padding: 0.05rem;
  }
}

@media screen and (min-width: 820px) {
  .row-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .img-container-1,
  .img-container-2 {
    flex: 1;
    justify-content: flex-end;
  }

  .siteLink {
    margin-left: -11.8rem;
  }

  .subject-container {
    padding-bottom: 4rem;
  }

  .text-container {
    flex: 1;
    margin-top: -1rem;
    margin-left: -4rem;
  }

  .project-desc-container {
    width: 20rem;
    padding-left: 50vw;
    margin-left: -50vw;
  }

  .project-desc {
    text-align: left;
  }
  .project-name {
    text-align: left;
    margin-left: 3rem;
  }

  .github-container {
    margin-top: -1rem;
    margin-right: -2rem;
    padding-bottom: 2rem;
  }

  .github-icon {
    height: 3rem;
    padding-right: 2rem;
    fill: var(--color-tertiary);
  }

  .github-bar {
    height: 3rem;
  }
}

@media screen and (min-width: 1000px) {
  .project-desc {
    text-align: left;
  }
  .project-name {
    font-size: 42px;
    text-align: left;
    margin-left: 3rem;
    line-height: 42px;
  }

  .img-container-1 .project-img-1 {
    height: 20rem;
    padding: 0.05rem;
  }

  .img-container-2 .project-img-1 {
    height: 15rem;
    padding: 0.05rem;
  }

  .project-desc {
    font-size: 18px;
    padding: 1rem;
  }

  .img-container-1 {
    margin-left: 5rem;
    flex: 1;
  }
  .img-container-2 {
    margin-right: 2rem;
  }

  .text-container {
    flex: 2;
  }
}

@media screen and (min-width: 1120px) {
  .project-name {
    font-size: 48px;
    line-height: 50px;
    margin-left: 12rem;
    font-size: 42px;
    width: 30rem;
  }

  .project-desc {
    font-size: 24px;
    line-height: 30px;
    width: 25rem;
  }
  .project-desc-container {
    padding-right: 10rem;
    margin-left: calc(-50vw + 10rem);
  }

  .text-container {
    flex: 3;
    padding-bottom: 5rem;
  }
  .img-container-1,
  .img-container-2 {
    flex: 1;
  }

  .img-container-1 {
    margin-right: -5rem;
  }

  .github-container {
    margin-top: -2rem;
  }
  .github-icon,
  .github-bar {
    height: 4rem;
  }
}
