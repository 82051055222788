.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
}

.header-svg {
  height: 2rem;
  margin-left: -10rem;
  z-index: -10;
}

.header-text {
  color: var(--color-purple);
  text-transform: uppercase;
}

@media screen and (min-width: 760px) {
  .projects {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 1000px) {
  .header-svg {
    height: 2.5rem;
  }
  .header-text {
    font-size: 22px;
    margin-left: -1rem;
  }
}
