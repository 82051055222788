.Home {
  background: var(--color-secondary);
  padding-top: 4rem;
  padding-bottom: 2.2rem;
  text-align: center;
}

.socials-container {
  display: none;
}

.Container {
  flex-direction: column;
}

.background {
  display: none;
}

.hero-image {
  display: none;
}

.slider-container {
  text-align: center;
}

.slider {
  align-items: center;
  height: 60px;
}

.slogan {
  margin-top: 3rem;
}

.meet {
  font-size: 18px;
  color: var(--color-purple);
  line-height: 20px;
}

.title-header {
  margin-top: 4rem;
}

.line1,
.line2 {
  line-height: 20px;
  color: var(--color-white);
}

.github-home {
  height: 2rem;
}

.linkedin-home {
  height: 2rem;
}

.email-home {
  height: 2rem;
}

@media screen and (min-width: 760px) {
  .Home {
    padding: 7rem;
    text-align: left;
  }

  .title-header,
  .meet {
    padding-bottom: 1rem;
  }

  .slider-container {
    text-align: left;
  }
}

@media screen and (min-width: 1000px) {
  .home-container {
    display: flex;
    align-items: center;
  }

  .Home {
    flex: 2;
    height: 90vh;
  }

  .hero-image {
    display: flex;
    width: 25rem;
    z-index: 800;
    position: absolute;
    left: calc(66% - 4.2rem);
    top: 10rem;
  }

  .empty-container {
    flex: 1;
  }

  .slider {
    height: 70px;
  }

  .meet {
    font-size: 24px;
    line-height: 30px;
  }

  .title-header {
    margin-top: 4rem;
  }

  .line1,
  .line2 {
    line-height: 30px;
    font-size: 42px;
  }
}

@media screen and (min-width: 1160px) {
  .hero-image {
    width: 30rem;
    left: calc(66% - 6rem);
  }

  .line1,
  .line2 {
    line-height: 36px;
    font-size: 48px;
    word-spacing: 5px;
    letter-spacing: 1px;
  }

  .slider {
    height: 75px;
  }

  .title-header {
    padding-bottom: 3rem;
  }

  .meet {
    padding-bottom: 2rem;
  }
}

@media screen and (min-width: 1200px) {
  .Home {
    padding: 10rem;
  }

  .hero-image {
    top: 35vh;
    left: calc(66% - 3.9rem);
  }
}
