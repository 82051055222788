.NavBar {
  background: transparent;
  height: 3.5rem;
  width: 100%;
  position: fixed;
  z-index: 999;
  display: flex;
  align-items: center;
}

.NavBar.active {
  background: var(--color-menuBar);
}

.logo {
  display: inline-block;
  flex: 33%;
  font-size: 24px;
  font-weight: 600;
}
.logo a {
  text-decoration: none;
}

.firstName {
  float: left;
  padding-left: 1rem;
  padding-right: 0.3rem;
  color: var(--color-white);
}

.lastName {
  color: var(--color-tertiary);
}

nav {
  flex: 66%;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  font-weight: normal;
}

nav ul li a {
  text-decoration: none;
  color: var(--color-white);
}

nav ul li a:hover {
  border-bottom: 0.2rem solid var(--color-tertiary);
  transition: all 0.2s ease-in;
}

nav ul li a:active {
  color: var(--color-tertiary);
}

.menuBars {
  color: var(--color-white);
  padding: 0.5rem 1rem;
}

.navMenu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  width: 60vw;
  justify-content: end;
}

.socials-bar {
  display: none;
}
.github-nav,
.linkedin-nav,
.email-nav {
  height: 2rem;
  display: none;
}

@media screen and (max-width: 760px) {
  nav ul {
    display: none;
  }
  .logo {
    display: inline-block;
    font-size: 1.2rem;
    flex: 50%;
  }

  .navMenu {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 92vh;
    position: absolute;
    top: 3.5rem;
    right: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .navMenu.active {
    background: var(--color-menuBar);
    right: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
}

@media screen and (min-width: 1000px) {
  .NavBar {
    justify-content: left;
  }
  .socials-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex: 9;
  }

  .logo {
    flex: 3;
  }

  .nav-bar {
    flex: 3;
  }

  .github-nav,
  .linkedin-nav,
  .email-nav {
    padding: 0.5rem 1rem;
    display: flex;
  }
}
