@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;900&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Source Sans Pro", sans-serif;
}

html {
  --color-secondary: #1881f2;
  --color-primary: #98c4f9;
  --color-purple: #bf2be2;
  --color-gray: #444444;
  --color-menuBar: #187ae2;
  --color-tertiary: #f79f2b;
  --color-white: #ffffff;
  scroll-behavior: smooth;
}

h1 {
  font-size: 32px;
  line-height: 35px;
  letter-spacing: 0.5px;
}

h2 {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 25px;
}

h3 {
  font-size: 18px;
  line-height: 38px;
  letter-spacing: 3px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.NavBar {
  background: transparent;
  height: 3.5rem;
  width: 100%;
  position: fixed;
  z-index: 999;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.NavBar.active {
  background: var(--color-menuBar);
}

.logo {
  display: inline-block;
  -webkit-flex: 33% 1;
          flex: 33% 1;
  font-size: 24px;
  font-weight: 600;
}
.logo a {
  text-decoration: none;
}

.firstName {
  float: left;
  padding-left: 1rem;
  padding-right: 0.3rem;
  color: var(--color-white);
}

.lastName {
  color: var(--color-tertiary);
}

nav {
  -webkit-flex: 66% 1;
          flex: 66% 1;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  font-weight: normal;
}

nav ul li a {
  text-decoration: none;
  color: var(--color-white);
}

nav ul li a:hover {
  border-bottom: 0.2rem solid var(--color-tertiary);
  transition: all 0.2s ease-in;
}

nav ul li a:active {
  color: var(--color-tertiary);
}

.menuBars {
  color: var(--color-white);
  padding: 0.5rem 1rem;
}

.navMenu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  width: 60vw;
  -webkit-justify-content: end;
          justify-content: end;
}

.socials-bar {
  display: none;
}
.github-nav,
.linkedin-nav,
.email-nav {
  height: 2rem;
  display: none;
}

@media screen and (max-width: 760px) {
  nav ul {
    display: none;
  }
  .logo {
    display: inline-block;
    font-size: 1.2rem;
    -webkit-flex: 50% 1;
            flex: 50% 1;
  }

  .navMenu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
    width: 100%;
    height: 92vh;
    position: absolute;
    top: 3.5rem;
    right: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .navMenu.active {
    background: var(--color-menuBar);
    right: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
}

@media screen and (min-width: 1000px) {
  .NavBar {
    -webkit-justify-content: left;
            justify-content: left;
  }
  .socials-bar {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 100%;
    -webkit-flex: 9 1;
            flex: 9 1;
  }

  .logo {
    -webkit-flex: 3 1;
            flex: 3 1;
  }

  .nav-bar {
    -webkit-flex: 3 1;
            flex: 3 1;
  }

  .github-nav,
  .linkedin-nav,
  .email-nav {
    padding: 0.5rem 1rem;
    display: -webkit-flex;
    display: flex;
  }
}

.Home {
  background: var(--color-secondary);
  padding-top: 4rem;
  padding-bottom: 2.2rem;
  text-align: center;
}

.socials-container {
  display: none;
}

.Container {
  -webkit-flex-direction: column;
          flex-direction: column;
}

.background {
  display: none;
}

.hero-image {
  display: none;
}

.slider-container {
  text-align: center;
}

.slider {
  -webkit-align-items: center;
          align-items: center;
  height: 60px;
}

.slogan {
  margin-top: 3rem;
}

.meet {
  font-size: 18px;
  color: var(--color-purple);
  line-height: 20px;
}

.title-header {
  margin-top: 4rem;
}

.line1,
.line2 {
  line-height: 20px;
  color: var(--color-white);
}

.github-home {
  height: 2rem;
}

.linkedin-home {
  height: 2rem;
}

.email-home {
  height: 2rem;
}

@media screen and (min-width: 760px) {
  .Home {
    padding: 7rem;
    text-align: left;
  }

  .title-header,
  .meet {
    padding-bottom: 1rem;
  }

  .slider-container {
    text-align: left;
  }
}

@media screen and (min-width: 1000px) {
  .home-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }

  .Home {
    -webkit-flex: 2 1;
            flex: 2 1;
    height: 90vh;
  }

  .hero-image {
    display: -webkit-flex;
    display: flex;
    width: 25rem;
    z-index: 800;
    position: absolute;
    left: calc(66% - 4.2rem);
    top: 10rem;
  }

  .empty-container {
    -webkit-flex: 1 1;
            flex: 1 1;
  }

  .slider {
    height: 70px;
  }

  .meet {
    font-size: 24px;
    line-height: 30px;
  }

  .title-header {
    margin-top: 4rem;
  }

  .line1,
  .line2 {
    line-height: 30px;
    font-size: 42px;
  }
}

@media screen and (min-width: 1160px) {
  .hero-image {
    width: 30rem;
    left: calc(66% - 6rem);
  }

  .line1,
  .line2 {
    line-height: 36px;
    font-size: 48px;
    word-spacing: 5px;
    letter-spacing: 1px;
  }

  .slider {
    height: 75px;
  }

  .title-header {
    padding-bottom: 3rem;
  }

  .meet {
    padding-bottom: 2rem;
  }
}

@media screen and (min-width: 1200px) {
  .Home {
    padding: 10rem;
  }

  .hero-image {
    top: 35vh;
    left: calc(66% - 3.9rem);
  }
}

.about {
  -webkit-flex-direction: column;
          flex-direction: column;
  background: var(--color-white);
  padding-bottom: 1rem;
  overflow: hidden;
}

.body-container {
  background: var(--color-tertiary);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: calc(250px + 11rem);
}

.rectangle-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  margin-top: calc(-250px - 1rem);
}

.desc-blue {
  display: none;
}

.funFact {
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: 2rem;
  width: 21rem;
  color: var(--color-white);
}

.desc-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background: var(--color-white);
  margin-top: -2rem;
  margin-left: -2rem;
  margin-right: 1rem;
  margin-bottom: 3rem;
  height: 250px;
  border-radius: 7px;
}

.desc {
  margin-left: 4rem;
  margin-right: 1rem;
  font-size: 11px;
  line-height: 15px;
  color: var(--color-gray);
}

.header-image {
  margin-left: -0.5rem;
  height: 6rem;
  z-index: -10;
  margin-top: 4rem;
}

.aboutMe {
  color: var(--color-purple);
  margin-left: 2rem;
  margin-top: -4.4rem;
  text-transform: uppercase;
}

@media screen and (min-width: 490px) {
  .desc {
    font-size: 16px;
    line-height: 28px;
  }
}

@media screen and (min-width: 760px) {
  .funFact {
    margin-top: 5rem;
    margin-bottom: 5rem;
    margin-left: 3rem;
  }

  .desc-container {
    -webkit-flex: 7 1;
            flex: 7 1;
    padding: 0;
    margin: 0;
    height: 300px;
  }

  .desc {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .desc-blue {
    display: -webkit-flex;
    display: flex;
    height: 300px;
    -webkit-flex: 3 1;
            flex: 3 1;
    border-radius: 7px;
    background-color: var(--color-secondary);
  }

  .body-container {
    width: 70%;
    height: calc(300px + 15rem);
  }

  .rectangle-container {
    margin: 0;
    margin-top: calc(-300px - 3rem);
    padding: 0;
    width: 100%;
  }

  .funFact {
    margin-left: 4rem;
    margin-top: 4rem;
  }
}

@media screen and (min-width: 1000px) {
  .aboutMe {
    font-size: 22px;
    margin-left: 3rem;
    margin-top: -5.5rem;
    text-transform: uppercase;
  }
  .header-image {
    margin-left: -0.5rem;
    height: 8rem;
    z-index: -10;
    margin-top: 4rem;
  }

  .funFact {
    line-height: 45px;
    font-size: 42px;
    margin-top: 4rem;
    margin-left: 3rem;
    width: 25rem;
  }

  .desc {
    font-size: 18px;
    margin-left: 3rem;
    margin-right: 3rem;
  }
}

@media screen and (min-width: 1120px) {
  .desc {
    font-size: 24px;
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .funFact {
    line-height: 52px;
    font-size: 48px;
  }

  .body-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
    height: 35rem;
  }

  .rectangle-container {
    margin-top: -20rem;
  }
}

.skills {
  width: 100%;
  padding-bottom: 3rem;
}

.header {
  margin-left: -1rem;
  height: 6rem;
  z-index: -10;
  margin-top: 0.5rem;
}

.skills-section {
  background: var(--color-tertiary);
  height: 17rem;
}

.skills-text {
  color: var(--color-purple);
  margin-left: 2rem;
  margin-top: -4.4rem;
  text-transform: uppercase;
}

.grid-container {
  display: grid;
  margin-top: 1rem;
  padding: 2rem;
  grid-template-columns: 40% 40%;
  grid-template-rows: 2rem 2rem;
  grid-column-gap: 10%;
  grid-row-gap: 20%;
}

.grid-item-1 {
  grid-column: 1 / 2;
}
.item-1 {
  text-align: center;
  color: var(--color-white);
  letter-spacing: 0.5px;
}

.grid-item-2 {
  grid-column: 2 / 4;
}
.item-2 {
  text-align: center;
  color: var(--color-white);
  letter-spacing: 0.5px;
}

.grid-item-3 {
  grid-row: 2 / 4;
  grid-column: 1 / 2;
}
.skill-card {
  background: var(--color-primary);
  border-radius: 7px;
  padding-top: 20px;
  padding-bottom: 5px;
}
.skill {
  margin-top: -0.5rem;
  text-align: center;
  color: var(--color-gray);
}

.grid-item-4 {
  grid-row: 2 / 4;
  grid-column: 2 / 4;
}

.resumepopup-container {
  display: none;
}

@media screen and (min-width: 760px) {
  .grid-container {
    padding: 3rem;
    grid-row-gap: 4rem;
    grid-template-columns: 40% 30%;
    grid-column-gap: 10%;
    grid-template-rows: 2rem 2rem;
  }

  .skills-section {
    height: 20rem;
    width: 70%;
  }

  .resumepopup-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-left: 70vw;
    margin-top: -12rem;
  }

  .resume-popup {
    color: var(--color-gray);
    margin-top: -0.2rem;
  }

  .skills {
    padding-bottom: 7rem;
  }
}

@media screen and (min-width: 1000px) {
  .skills-text {
    font-size: 22px;
    margin-left: 3rem;
    margin-top: -5.5rem;
    text-transform: uppercase;
  }

  .header {
    margin-left: -0.5rem;
    height: 8rem;
    z-index: -10;
    margin-top: 4rem;
  }

  .skill {
    font-size: 18px;
  }

  .item-1,
  .item-2 {
    font-size: 42px;
  }

  .resume-popup {
    font-size: 18px;
  }
}

@media screen and (min-width: 1120px) {
  .item-1,
  .item-2 {
    font-size: 48px;
    line-height: 50px;
  }

  .grid-container {
    grid-row-gap: 6rem;
  }

  .skill,
  .resume-popup {
    font-size: 24px;
    line-height: 40px;
  }

  .skills-section {
    height: 30rem;
  }
}

.header-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 2rem;
}

.header-svg {
  height: 2rem;
  margin-left: -10rem;
  z-index: -10;
}

.header-text {
  color: var(--color-purple);
  text-transform: uppercase;
}

@media screen and (min-width: 760px) {
  .projects {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

@media screen and (min-width: 1000px) {
  .header-svg {
    height: 2.5rem;
  }
  .header-text {
    font-size: 22px;
    margin-left: -1rem;
  }
}

.subject-container {
  width: 100%;
  overflow: hidden;
}

.img-container-1 {
  z-index: 10;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  grid-gap: 1rem;
  gap: 1rem;
}

.img-container-2 {
  z-index: 10;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.project-card-1 {
  background: var(--color-secondary);
  padding: 0.5rem;
  border-radius: 7px;
}

.img-container-1 .project-img-1 {
  height: 12rem;
  padding: 0.05rem;
}

.img-container-2 .project-img-1 {
  height: 7rem;
  padding: 0.05rem;
}

.text-container {
  background: var(--color-primary);
  padding-top: 4rem;
  padding-bottom: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.project-desc {
  color: var(--color-gray);
  text-align: center;
  padding: 1rem;
}

.project-desc-container {
  border-radius: 7px;
  background-color: var(--color-white);
}

.project-name {
  text-align: center;
  width: 21rem;
  color: var(--color-white);
}

.siteLink {
  z-index: 10;
  color: var(--color-secondary);
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  margin-top: -1rem;
  padding-bottom: 1rem;
}

.github-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  margin-top: -1rem;
  margin-right: -10rem;
  padding-bottom: 5rem;
  overflow: hidden;
}

.github-icon {
  height: 3rem;
  padding-right: 2rem;
  fill: var(--color-tertiary);
}

.github-bar {
  height: 3rem;
}

@media screen and (min-width: 490px) {
  .img-container-1 .project-img-1 {
    height: 15rem;
    padding: 0.05rem;
  }

  .img-container-2 .project-img-1 {
    height: 10rem;
    padding: 0.05rem;
  }
}

@media screen and (min-width: 820px) {
  .row-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }

  .img-container-1,
  .img-container-2 {
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }

  .siteLink {
    margin-left: -11.8rem;
  }

  .subject-container {
    padding-bottom: 4rem;
  }

  .text-container {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-top: -1rem;
    margin-left: -4rem;
  }

  .project-desc-container {
    width: 20rem;
    padding-left: 50vw;
    margin-left: -50vw;
  }

  .project-desc {
    text-align: left;
  }
  .project-name {
    text-align: left;
    margin-left: 3rem;
  }

  .github-container {
    margin-top: -1rem;
    margin-right: -2rem;
    padding-bottom: 2rem;
  }

  .github-icon {
    height: 3rem;
    padding-right: 2rem;
    fill: var(--color-tertiary);
  }

  .github-bar {
    height: 3rem;
  }
}

@media screen and (min-width: 1000px) {
  .project-desc {
    text-align: left;
  }
  .project-name {
    font-size: 42px;
    text-align: left;
    margin-left: 3rem;
    line-height: 42px;
  }

  .img-container-1 .project-img-1 {
    height: 20rem;
    padding: 0.05rem;
  }

  .img-container-2 .project-img-1 {
    height: 15rem;
    padding: 0.05rem;
  }

  .project-desc {
    font-size: 18px;
    padding: 1rem;
  }

  .img-container-1 {
    margin-left: 5rem;
    -webkit-flex: 1 1;
            flex: 1 1;
  }
  .img-container-2 {
    margin-right: 2rem;
  }

  .text-container {
    -webkit-flex: 2 1;
            flex: 2 1;
  }
}

@media screen and (min-width: 1120px) {
  .project-name {
    font-size: 48px;
    line-height: 50px;
    margin-left: 12rem;
    font-size: 42px;
    width: 30rem;
  }

  .project-desc {
    font-size: 24px;
    line-height: 30px;
    width: 25rem;
  }
  .project-desc-container {
    padding-right: 10rem;
    margin-left: calc(-50vw + 10rem);
  }

  .text-container {
    -webkit-flex: 3 1;
            flex: 3 1;
    padding-bottom: 5rem;
  }
  .img-container-1,
  .img-container-2 {
    -webkit-flex: 1 1;
            flex: 1 1;
  }

  .img-container-1 {
    margin-right: -5rem;
  }

  .github-container {
    margin-top: -2rem;
  }
  .github-icon,
  .github-bar {
    height: 4rem;
  }
}

.header-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 4rem;
}

.header-text {
  color: var(--color-purple);
  margin-left: 2rem;
  text-transform: uppercase;
}

.info-container {
  background: var(--color-secondary);
  padding: 3rem;
}

.looking-forward {
  color: var(--color-white);
}
.contacts-container {
  margin-top: 3rem;
}

.email {
  color: var(--color-tertiary);
}

.github-svg,
.linkedin-svg {
  width: 3rem;
  padding-right: 2rem;
}

.file-svg {
  width: 2rem;
  padding-right: 1rem;
}

.download-resume {
  color: var(--color-primary);
}

.resume-container {
  margin-top: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

@media screen and (min-width: 1000px) {
  .looking-forward {
    font-size: 42px;
    line-height: 50px;
  }

  .email {
    font-size: 18px;
  }
  .download-resume {
    font-size: 18px;
  }
}

@media screen and (min-width: 1120px) {
  .looking-forward {
    font-size: 48px;
    line-height: 50px;
  }

  .email,
  .download-resume {
    font-size: 24px;
    line-height: 28px;
  }

  .contacts-container {
    margin-top: 6rem;
  }
  .resume-container {
    margin-top: 4rem;
  }
}

