.skills {
  width: 100%;
  padding-bottom: 3rem;
}

.header {
  margin-left: -1rem;
  height: 6rem;
  z-index: -10;
  margin-top: 0.5rem;
}

.skills-section {
  background: var(--color-tertiary);
  height: 17rem;
}

.skills-text {
  color: var(--color-purple);
  margin-left: 2rem;
  margin-top: -4.4rem;
  text-transform: uppercase;
}

.grid-container {
  display: grid;
  margin-top: 1rem;
  padding: 2rem;
  grid-template-columns: 40% 40%;
  grid-template-rows: 2rem 2rem;
  grid-column-gap: 10%;
  grid-row-gap: 20%;
}

.grid-item-1 {
  grid-column: 1 / 2;
}
.item-1 {
  text-align: center;
  color: var(--color-white);
  letter-spacing: 0.5px;
}

.grid-item-2 {
  grid-column: 2 / 4;
}
.item-2 {
  text-align: center;
  color: var(--color-white);
  letter-spacing: 0.5px;
}

.grid-item-3 {
  grid-row: 2 / 4;
  grid-column: 1 / 2;
}
.skill-card {
  background: var(--color-primary);
  border-radius: 7px;
  padding-top: 20px;
  padding-bottom: 5px;
}
.skill {
  margin-top: -0.5rem;
  text-align: center;
  color: var(--color-gray);
}

.grid-item-4 {
  grid-row: 2 / 4;
  grid-column: 2 / 4;
}

.resumepopup-container {
  display: none;
}

@media screen and (min-width: 760px) {
  .grid-container {
    padding: 3rem;
    grid-row-gap: 4rem;
    grid-template-columns: 40% 30%;
    grid-column-gap: 10%;
    grid-template-rows: 2rem 2rem;
  }

  .skills-section {
    height: 20rem;
    width: 70%;
  }

  .resumepopup-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 70vw;
    margin-top: -12rem;
  }

  .resume-popup {
    color: var(--color-gray);
    margin-top: -0.2rem;
  }

  .skills {
    padding-bottom: 7rem;
  }
}

@media screen and (min-width: 1000px) {
  .skills-text {
    font-size: 22px;
    margin-left: 3rem;
    margin-top: -5.5rem;
    text-transform: uppercase;
  }

  .header {
    margin-left: -0.5rem;
    height: 8rem;
    z-index: -10;
    margin-top: 4rem;
  }

  .skill {
    font-size: 18px;
  }

  .item-1,
  .item-2 {
    font-size: 42px;
  }

  .resume-popup {
    font-size: 18px;
  }
}

@media screen and (min-width: 1120px) {
  .item-1,
  .item-2 {
    font-size: 48px;
    line-height: 50px;
  }

  .grid-container {
    grid-row-gap: 6rem;
  }

  .skill,
  .resume-popup {
    font-size: 24px;
    line-height: 40px;
  }

  .skills-section {
    height: 30rem;
  }
}
